.SecondaryCTA {
	padding: 10px 20px;
	background-color: transparent;
	border: 2px solid #0D3044;
	color: #D1DEE8;
	font-weight: 400;
	border-radius: 5px;
	margin: auto;
	transition: all 0.7s;
}

.SecondaryCTA:hover {
	background-color: #0D3044;
}

@media (max-width: 600px) {
	.SecondaryCTA {
		padding: 5px 10px;
		font-size: 0.7em;
	}
}