.PrimaryCTA {
	padding: 10px 20px;
	background-color: #152B3D;
	border: none;
	color: #FFF;
	font-weight: 400;
	border-radius: 5px;
	margin: auto;
	width: fit-content;
	white-space: nowrap;
	transition: all 0.7s;
}

.PrimaryCTA:hover {
	background-color: #206085;
}

@media (max-width: 480px) {
	.PrimaryCTA {
		font-size: 0.6em;
		padding: 8px 10px;
	}
}