.FilterItemWrapper {
	display: flex;
	flex-direction: row;
	gap: 5px;
	padding: 5px 10px;
	cursor: pointer;
	align-items: center;
}

.FilterItemWrapper:hover {
	background-color: #071C31;
	padding: 5px 10px;
	border-radius: 10px;
}

.FilterIcon {
	width: 20px;
	height: 20px;
	margin-right: 5px;
}

.FilterName{
	height: fit-content;
}

.Selected {
	background-color: #0D3044;
	padding: 5px 10px;
	border-radius: 10px;
}

@media (max-width: 600px) {
	.FilterItemWrapper {
		font-size: 0.65em;
	}
}