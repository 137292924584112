.DungeonsWrapper{
	display: grid;
	grid-template-columns: 500px 500px;
	flex-direction: column;
	margin-bottom: 100px;
	justify-content: space-between;
	width: 100%;
	margin: auto;
	gap: 100px;
}

a{
	/* margin: auto; */
}

@media (max-width: 1000px) {
	.DungeonsWrapper{
		gap: 30px;
		display: flex;
	}
}