.SquadItem {
	display: flex;
	flex-direction: row;
	background-color: #05172B;
	gap: 20px;
	justify-content: space-between;
	border-radius: 5px;
}

.SquadGuardianInfo {
	display: flex;
	flex-direction: row;
}

.SquadAttributesWrapper {
	display: flex;
	flex-direction: row;
	gap: 40px;
	margin-left: 40px;
}

.SquadAttributesTextWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
}

.SquadAttributes {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.SquadGuardianImage {
	width: 100px;
	height: 100px;
}

.SquadAttributeIcon {
	width: 12px;
	margin-bottom: 10px;
}

.SquadRole {
	font-size: 0.7em;
	color: #777F8A;
	font-weight: 300;
}

.SquadRoleText {
	color: #858B95;
	font-size: 1.0em;
}

.SquadGuardianName {
	color: #6E7480;
	font-size: 1.2em;
	margin: auto 10px;
}

.SquadGuardianDeleteIcon {
	position: relative;
	cursor: pointer;
	padding-right: 20px;
}

.SquadAttributesTextContainer{
	display: grid;
	flex-direction: row;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 40px;
}

@media (max-width: 600px) {
	.SquadGuardianImage{

	}

	.SquadAttributesWrapper{
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-left: 10px;
	}


	.SquadGuardianName{
		font-size: 0.9em;
		margin: 0;
		text-align: left;
		margin-top: 5px;
	}

	.SquadAttributesTextContainer{
		grid-template-columns: 1fr 1fr;
		gap: 10px;
	}

	.SquadRole{
		font-size: 0.6em;
	}

	.SquadRoleText{
		font-size: 0.7em;
	}

	.SquadGuardianDeleteIcon{
		padding-right: 10px;
		width: 15px;
	}
}