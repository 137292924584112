.AdminCategory {
	display: flex;
	flex-direction: column;
	width: 800px;
	margin: auto;
	gap: 10px;
}

.AdminPage {
	display: flex;
	flex-direction: row;
}

.wikiText {
	height: 500px;
}

.GuardianWiki {
	height: fit-content;
	text-align: left;
	background-color: black;
	width: 50%;
	margin: auto;
}

.AdminContentWrapper {
	max-width: 1280px;
	margin: 100px auto;
	width: 70%;
}

.AdminButtonWrapper{
	display: flex;
	flex-direction: row;
	gap: 35px;
	align-items: center;
}