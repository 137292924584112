.DungeonCardWrapper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	max-width: 1280px;
	height: 240px;
	background-blend-mode: overlay;
	background-position: center;
	margin: auto;
	border-radius: 5px;
}

.DungeonWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 25px;
	transition: all 0.5s;
}

.DungeonCardContent {
	display: flex;
	flex-direction: column;
	text-align: left;
	gap: 20px;
	width: 75%;
	padding-left: 50px;
}

.DungeonHeading {
	font-size: 1.6em;
}

.DungeonText {
	font-size: 1em;
	font-weight: 300;
	width: 100%;
}

.ElipsisWrapper {
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ElipsisWrapper:hover {
	border-radius: 50px;
	background-color: rgba(256, 256, 256, 0.1);
}

.ElipsisIcon {
	margin: auto;
}

.DungeonWrapper:hover {
	opacity: 0.75;
}

@media (max-width: 1000px) {
	.DungeonCardWrapper {
		height: 180px;
	}
}

@media (max-width: 600px) {
	.DungeonCardWrapper {
		width: 100%;
		height: 120px;
	}

	.DungeonCardContent {
		width: 90%;
		padding-left: 10px;
	}

	.DungeonHeading {
		font-size: 1em
	}

	.DungeonText {
		font-size: 0.8em
	}
}