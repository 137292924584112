.MainInput {
	background-color: #283143;
	border: none;
	border-radius: 5px;
	width: 100%;
	max-width: 500px;
	padding: 10px;
	color: #686E7B;
	font-size: 1em;
}

.EmptyInput {
	border: 1px solid red;
}

.MainInputWrapper {
	display: flex;
	flex-direction: column;
	text-align: left;
	width: 100%;
	gap: 10px;
}

@media (max-width: 600px) {
	.MainInput {
		width: 80%;
		font-size: 0.7em;
	}
}