.MobileNavbar {}

.MobileNavBarContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	z-index: 999;
	position: fixed;
	top: 0;
	background-color: #000A1D;
}

.MobileNavBarWrapper {
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	padding: 0 10px;
	justify-content: space-between;
}

.Logo {
	width: 25px;
}

.LogoContainer {
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
}

.NavHamburgerIcon {
	width: 18px;
}

.NavGreeting {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.NavUserName {
	display: flex;
	font-size: 0.7em;
	align-items: center;
}

.NavBarContentWrapper {
	flex-direction: column;
	position: fixed;
	background-color: #1D2637;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 9999;
}

.NavBarContentContainer {
	display: flex;
	flex-direction: column;
	gap: 50px;
	margin-top: 35%;
	align-items: center;
}

.NavBarContentText {
	font-size: 1.2em;
}