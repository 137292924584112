.InputWrapper{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 40px;
}

.MainInputContainer{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 35px;
}

.MainInputWrapper{
	display: flex;
	flex-direction: column;
	gap: 35px;
	width: 35%;
}