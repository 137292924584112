.Overlay {
	border-radius: 5px;
	padding-bottom: 80px;
}

.TeamCompositionOverlay {
	background-color: #1D2637;
	width: 60%;
	padding: 15px 50px 10px 50px;
	margin: auto;
}

.SavedSquadWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.SquadInputContainer {
	display: flex;
	flex-direction: row;
	gap: 50px;
	width: fit-content;
	margin-bottom: 30px;
	align-items: center;
}

.SquadInputWrapper{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.CloseOverlayButton{
	background: none;
	width: fit-content;
	height: fit-content;
	color: #A5B4D2;
	border: none;
	font-size: 1.5em;
}

.SavedSquadContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	width: fit-content;
	justify-content: space-between;
	flex-direction: row;
	gap: 15px;
	margin-bottom: 35px;
}

.SquadOverviewContainer {
	display: grid;
	flex-direction: column;
	gap: 15px;
}

.SquadItemContainer {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.SquadOverviewHeading,
.SavedSquadHeading,
.SquadHeading {
	color: #A5B4D2;
	font-size: 1.2rem;
	width: fit-content;
}

.SavedSquad {
	width: 100px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin: auto;
}

.SavedSquadLetter {
	font-size: 2em;
	background-color: #142C3F;
	color: #6A7E97;
	padding: 10px 5px 10px 5px;
	width: 60%;
	margin: auto;
	border-radius: 5px;
}


.SquadName {
	text-align: center;
	color: #8A98B3;
}

@media (max-width: 1000px) {
	.TeamCompositionOverlay{
		width: 80%;
		padding: 25px;
	}

	.SearchContainer {
		flex-direction: column;
		width: 100%;
	}

	.SavedSquadContainer{
		grid-template-columns: 1fr 1fr 1fr;
		width: 100%;
		margin: auto;
	}

	.SavedSquadHeading{
		font-size: 0.8em;
	}

	.SavedSquad{
		width: 75px;
	}

	.SavedSquadLetter{
		width: 50%;
		font-size: 1.5em;
	}

	.SquadName{
		font-size: 0.7em;
	}

	.SquadOverviewHeading{
		color: #A5B4D2;
		font-size: 0.8em;
	}
}