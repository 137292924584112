.CategoryCardWrapper {
	width: 100%;
	height: 200px;
	background-color: #1D2637;
	margin: auto;
	margin-bottom: 15px;
	border-radius: 10px;
	transition: all 0.5s;
}

.CategoryCard {
	display: flex;
	flex-direction: row;
	gap: 35px;
	transition: all 0.5s;
}

.CategoryImage {
	height: 200px;
	border-radius: 10px 0 0 10px;
}

.CategoryInfo {
	display: flex;
	flex-direction: column;
	text-align: left;
	gap: 15px;
	margin-top: 25px;
}

.CategoryName {
	font-weight: 500;
	color: #B0BED1;
	font-size: 1.2em;
}

.CategoryDescription {
	font-weight: 200;
	opacity: 0.5;
	font-size: 1em;
	width: 85%;
}

.CategoryCard:hover {
	opacity: 0.75;
}


/* Media queries */

@media (max-width: 1000px) {
	.CategoryCardWrapper {
		height: 120px;
	}

	.CategoryImage {
		height: 120px;
	}

	.CategoryDescription {
		width: 90%;
		font-size: 0.8em;
	}

	.CategoryCard {
		gap: 25px;
	}

	.CategoryInfo {
		gap: 8px;
		margin-top: 10px;
	}

	.CategoryName {
		font-size: 1em;
	}
}

@media (max-width: 768px) {
	.CategoryCardWrapper {
		height: 120px;
	}

	.CategoryImage {
		height: 120px;
	}

	.CategoryDescription {
		width: 90%;
	}

	.CategoryCard {
		gap: 25px;
	}

	.CategoryInfo {
		gap: 8px;
		margin-top: 10px;
	}
}

@media (max-width: 480px) {
	.CategoryCardWrapper {
		min-width: 280px;
		height: 120px;
		border-radius: 5px;
	}

	.CategoryCard {
		gap: 20px;
	}

	.CategoryImage {
		height: 120px;
		border-radius: 5px 0 0 5px;
	}

	.CategoryDescription {
		font-size: 0.75em;
	}

	.CategoryInfo {
		margin-top: 5px;
		gap: 10px;
	}

	.CategoryName{
		font-size: 0.9em;
	}
}