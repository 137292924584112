.DungeonImage {
	width: 640px;
	height: fit-content;
}

.DungeonContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: auto;
	gap: 60px;
}

.DungeonInfoWrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 300px;
	width: 100%;
	text-align: left;
}

.WikiTextContainer {
	text-align: left;
}

.WikiTextHeading {
	color: #6E7480;
}

.WikiText {
	color: #D2E0ED;
}

.DungeonName{
	font-size: 1.1em;
	margin-top: 35px;
}

@media (max-width: 1000px) {
	.DungeonInfoWrapper{
		flex-direction: column;
	}

	.DungeonImage{
		width: 100%;
	}

	h2 {
		text-align: left;
		color: #6E7480;
		font-size: 1em;
	}

	p {
		text-align: left;
		color: #D2E0ED;
		font-size: 0.8em;
	}

	.DungeonInfoWrapper{
		gap: 30px;
	}
}