.InputsWrapper {
	display: flex;
	flex-direction: column;
	gap: 35px;
	width: 40%;
}

.PageInputContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	border-bottom: 1px #141D2F solid;
	padding-bottom: 50px;
}

.PageContent {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.MainDropdownWrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 35px;
}