.PetsPage {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	gap: 100px;
}

.PetsPageContent {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, auto));
	gap: 60px;
	width: 100%;
	align-items: center;
	justify-content: space-around;
}

.PetsContentWrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.PageHeading {
	font-weight: 600;
}

@media (max-width: 900px) {
	.PetsPageContent {
		grid-template-columns: repeat(auto-fill, minmax(150px, auto));
	}

}

@media (max-width: 600px) {
	.PetsPageContent {
		gap: 10px;
	}
}