.AdminSideBar {
	position: fixed;
	padding-top: 80px;
	height: 100vh;
	background-color: #1D2637;
	width: 15%;
	max-width: 400px;
	min-width: 250px;
}

.AdminSideBarWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin: auto;
	width: 85%;
}

.AdminSideBarButton {
	background-color: #293141;
	color: #FFF;
	border: none;
	text-align: left;
	border-radius: 5px;
	width: 100%;
	margin: auto;
	padding: 10px;
	font-size: 0.9em;
	transition: all 1s;
}

.AdminSideBarWrapper a{
	width: 100%;
}

.AdminSideBarButton:hover {
	background-color: #344463;
}