/* SquadPowerBar.module.css */

.SquadPowerBar {
	background-color: #010917;
	border-radius: 2px;
	height: 20px;
	/* Set a default height */
	display: flex;
	/* Display as flex container to center align the power bar */
	align-items: center;
	/* Center the power bar vertically */
}

.SquadPower {
	background-color: #272D39;
	border-radius: 2px;
	height: 100%;
	/* Set the height to 100% to fill the container vertically */
	display: flex;
	/* Display as flex container */
	align-items: center;
	/* Center the power number vertically */
	overflow: hidden;
	/* Hide overflowing content */
	transition: width 0.3s ease;
	/* Add a transition for smooth width changes */
}

.SquadPowerNumber {
	color: #BEC0C3;
	margin-left: 10px;
	font-size: 0.9em;
	/* Set a default font size */
}

@media (max-width: 600px) {
	.SquadPowerBar {
		height: 15px;
		/* Adjust height for smaller screens */
	}

	.SquadPower {
		height: 100%;
		/* Keep the power bar height consistent */
	}

	.SquadPowerNumber {
		font-size: 0.7em;
		/* Adjust font size for smaller screens */
	}
}