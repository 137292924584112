.AuthForm {
	display: flex;
	flex-direction: column;
	gap: 20px;
	background-color: #1D2637;
	width: 40%;
	margin: auto;
	border-radius: 5px;
	padding: 20px 0 40px 0;
}

.AuthHeading {
	font-weight: 600;
}

.AuthInputWrapper {
	display: flex;
	flex-direction: column;
	/* width: 20%; */
	margin: auto;
	gap: 30px;
	margin-bottom: 20px;
}

.AuthButtonWrapper {
	display: flex;
	flex-direction: column;
	margin: auto;
	gap: 20px;
}

.LoginText{
	font-size: 0.8em;
	color: #A5A8AF;
}

.LoginContainer{
	display: flex;
	justify-content: center;
	margin-top: 100px;
}