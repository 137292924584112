.Filter {
	display: flex;
	flex-direction: column;
	height: fit-content;
	gap: 20px;
	background-color: #000A1D;
	margin-bottom: 50px;
	transition: all 1s;
}

.FilterMenuWrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-bottom: 35px;
}

.FilterHeading {
	color: #A5B4D2;
	font-size: 1.2em;
	margin-top: 20px;
}

.FilterCategoryWrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.FilterSearchContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #030F24;
	padding: 10px 20px
}

.FilterSearchWrapper {
	display: flex;
	flex-direction: row;
	width: fit-content;
	align-items: center;
	gap: 20px;
}

.DropdownButton {
	background-color: transparent;
	border: none;
}

.FilterSearchText {}

.SearchWrapper {
	display: flex;
	flex-direction: row;
	max-width: 450px;
	margin: auto;
	padding: 10px;
	gap: 40px;

}

.SearchInput {
	background-color: #283143;
	border: none;
	border-radius: 5px;
	width: 20rem;
	color: #686E7B;
	font-size: 1em;
	padding: 10px;
}

@media (max-width: 600px) {
	.Search {
		display: flex;
		flex-direction: column;
	}

	.SearchWrapper {
		gap: 20px;
	}

	.SearchInput {
		min-width: 150px;
		width: 40%;
		font-size: 0.7em;
		padding: 5px;
		height: 20px;
	}
}

@media (max-width: 600px) {
	.FilterSearchContainer {
		flex-direction: row;
		padding: 5px 10px;
		justify-content: space-between;
		gap: 15px;
	}

	.FilterSearchWrapper {
		gap: 5px;
	}

	.FilterSearchText {
		font-size: 0.7em;
	}
}