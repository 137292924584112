.HomeIntro {
	display: flex;
	flex-direction: column;
	background-image: url('../../assets/home-banner.png');
	background-repeat: no-repeat;
	background-size: cover;
	height: 30vh;
	max-height: 420px;
	min-height: 250px;
	/* margin: 40px; */
}

.HomeCategoryHeading {
	opacity: 0.2;
}

.HomeCategoryWrapper {
	margin-top: 50px;
}

.HomeCategory {
	display: grid;
	margin: auto;
	grid-template-columns: 1fr 1fr;
	flex-direction: column;
	gap: 60px;
	justify-content: space-between;
}

.HomeCategory a{
	width: 100%;
}

.HomeIntroText {
	margin: auto;
	font-size: 1em;
	font-weight: 300;
}

.HomeIntroWrapper {
	display: flex;
	flex-direction: column;
	gap: 40px;
	width: 40%;
	margin: auto;
}

.HomeIntroWrapper a {
	margin: auto;
}

.HomeIntroHeading {
	font-weight: 700;
	font-size: 1.8em;
	font-family: 'Albertus Medium', sans-serif;
}

a {
	width: fit-content;
	/* margin: auto; */
}

footer{
	font-size: 0.75em;
	opacity: 0.5;
	margin-top: 70px;
}

@media (max-width: 1000px) {
	.HomeIntroWrapper {
		width: 70%;
	}
}

@media (max-width: 700px) {
	.HomeIntro {
		max-height: 280px;
		margin: 15px 0;
	}

	.HomeCategory {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin: 15px;
	}

	.HomeIntroWrapper {
		gap: 20px;
	}

	.HomeIntroHeading {
		font-size: 1.2em;
		margin-bottom: 0;
	}
}

@media (max-width: 480px) {
	.HomeIntroWrapper {
		width: 95%;
		gap: 20px;
	}

	.HomeIntroText {
		width: 75%;
		font-size: 0.8em;
	}

	.HomeIntroHeading {
		font-size: 1.1em;
	}
}