.FilterCategoryWrapper {
	background-color: #030F24;
	padding: 20px;
	/* height: 30px; */
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.FilterCategoryContainer {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.FilterOptions {
	display: flex;
	flex-direction: row;
	gap: 40px;
}

.FilterCategoryTitle{
	opacity: 0.5;
	width: 50px;
	text-align: left;
}

@media (max-width: 600px) {
	.FilterCategoryWrapper{
		padding: 10px;
	}

	.FilterOptions{
		gap: 10px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}

	.FilterCategoryTitle{
		font-size: 0.65em;
	}
}