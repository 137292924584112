.PetContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: auto;
	gap: 60px;
	margin-bottom: 100px;
}

.PetInfoWrapper {
	display: flex;
	flex-direction: row;
	gap: 100px;
	margin-top: 50px;
	justify-content: space-between;
}

.PetInfo {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.PetImageWrapper {
	width: 50%;
}

.PetNameWrapper {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.PetName {
	color: #676B73;
	font-weight: 700;
	font-size: 2em;
}

.PetPriceWrapper {
	display: flex;
	flex-direction: column;
	width: 50%;
	text-align: left;
}

.PetPrice {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.PetDescription {
	color: #767B85;
	font-weight: 300;
	height: fit-content;
	font-size: 1em;
	margin: auto 5px;
	text-align: left;
}

.AbilitiesWrapper {
	display: flex;
	flex-direction: row;
	gap: 30px;
	margin: auto;
	justify-content: space-between;
}

.AbilityContainer {
	display: flex;
	flex-direction: row;
	background-color: #010917;
	width: 500px;
	gap: 30px;
	padding: 20px;
}

.AbilityContent {
	display: flex;
	flex-direction: column;
	text-align: left;
	gap: 20px;
	width: 70%;
}

.AttributesWrapper {
	background-color: #010917;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	padding: 10px;
	padding-right: 60px;
	gap: 40px;
	align-items: center;
}

.AttributeText {
	font-size: 1em;
	color: #848A93;
}

.AttributesContainer {
	display: flex;
	flex-direction: row;
	gap: 100px;
	text-align: left;
	width: 100%;
}

.Attribute {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: auto;
}

.PriceHeading {
	font-size: 0.8em;
	color: #767C85;
}

.ExtraAtrributesContainer {
	display: flex;
	flex-direction: row;
	background-color: #010917;
	gap: 20px;
	width: 300px;
	padding: 20px;
	padding-right: 40px;
}

.ExtraAtrributeTitleContainer {
	display: flex;
	flex-direction: column;
	background-color: #051224;
	gap: 20px;
	align-items: center;
}

.ExtraAtrributeTextContainer {
	display: flex;
	flex-direction: column;
	gap: 20px;
	text-align: left;
}

.ExtraAtrributes {
	display: flex;
	flex-direction: column;
}

.OverviewHeading {
	color: #6E7480;
}

.OverviewContainer {
	text-align: left;
	width: 360px;
}

.OverviewText {
	color: #D2E0ED;
	text-align: left;

}

.ETHPrice {
	font-size: 1.5em;
}

.USDPrice {
	color: #B0BEDA;
	margin: auto 5px;
}

.AbilityHeading {
	font-size: 0.8em;
	color: #767C86;
}

.AbilityText {
	color: #848A93;
	font-size: 1.2em;
}

.AbilityDescription {
	color: #D2E0ED;
	font-size: 0.8em;
}

.AbilityIcon {
	width: 60px;
}

.AttributesTextContainer {
	display: flex;
	flex-direction: column;
}

.AttributeIcon {
	width: 40px;
	max-height: 40px;
}

.WikiTextContainer {
	text-align: left;
}

.WikiTextHeading {
	color: #6E7480;
}

.WikiText {
	color: #D2E0ED;
}

.PetImage {
	width: 100%;
	margin-top: 25px;
	border-radius: 2px;
}

h1,
h2,
h3,
h4 {
	color: #6E7480;
}



@media (max-width: 600px) {
	.AttributesContainer {
		width: 280px;
	}

	.PetInfoWrapper {
		flex-direction: column;
		gap: 15px;
		margin-top: 10px;
	}

	.PetImage {
		width: 100%;
	}

	.AttributeIcon {
		width: 30px;
	}

	.AttributeHeading {
		font-size: 0.6em;
	}

	.AttributesWrapper {
		margin: auto;
		padding: 0;
	}

	.ExtraAtrributesContainer {
		margin: auto;
		width: 280px;
		padding-right: 0;
	}

	.AbilitiesWrapper {
		flex-direction: row;
	}

	.AbilityHeading {
		font-size: 0.6em;
	}

	.AbilityText {
		font-size: 0.8em;
	}

	.AbilityDescription {
		font-size: 0.7em;
	}

	h2 {
		text-align: left;
		color: #6E7480;
		font-size: 1em;
	}

	p {
		text-align: left;
		color: #D2E0ED;
		font-size: 0.8em;
	}

	.AbilityIcon {
		width: 40px;
	}

	.AbilityContent {
		gap: 10px;
	}

	.OverviewContainer {
		width: 100%;
	}

	.WikiTextContainer {
		margin: auto;
	}

	.AbilityContainer {
		width: fit-content;
		max-width: 300px;
	}

	.OverviewContainer {
		margin: auto;
	}

	.AttributeText {
		font-size: 0.8em;
	}

	.PetName {
		font-size: 1.1em;
	}

	.PetNameWrapper {
		gap: 5px;
	}

	.PetDescription {
		font-size: 0.6em;
	}

	.ETHPrice {
		font-size: 1em;
	}

	.USDPrice {
		font-size: 0.6em;
	}

	.PetPrice {
		gap: 5px;
	}

	.PriceHeading {
		font-size: 0.6em;
	}
}