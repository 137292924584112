.UserCardWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #172031;
	max-width: 480px;
	padding: 25px;
	border-radius: 10px;
}

.UserInfoWrapper {
	display: flex;
	flex-direction: row;
	width: 60%;
	justify-content: space-between;
	align-items: center;
}

.UserImage {
	background-color: #4845A1;
	height: 50px;
	width: 50px;
	border-radius: 50%;
}

.UserName {
	font-weight: 600;
	font-size: 1.1em;
}

.UserRole {
	color: #535A69;
	font-size: 0.9em;
}

.ElipsisWrapper {
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ElipsisWrapper:hover {
	border-radius: 50px;
	background-color: rgba(256, 256, 256, 0.1);
}

.ElipsisIcon {
	margin: auto;
}

.Dropdown {
	background-color: #283143;
	border: none;
	color: #DAE2F2;
	padding: 10px;
	border-radius: 5px;
}