.uploadedImage{
	width: 250px;
}

.imageUploadWrapper{
		
}

.imageUpload{
	
}