.SquadOverview {
	display: flex;
	flex-direction: row;
	background-color: #172031;
	border-radius: 5px;
	width: 100%;
	padding: 5px;
}

.SquadRoleIcon {
	width: 20px;
}

.SquadRoleWrapper {
	display: flex;
	flex-direction: row;
	padding: 10px;
	gap: 20px;
	justify-content: left;
	width: 20%;
	text-align: left;
}

.SquadRoleText {
	font-weight: 300;
	font-size: 1.2em;
	color: #7B808B;
}

.SquadStatWrapper {
	display: flex;
	flex-direction: row;
	gap: 50px;
	margin: auto 10px;
	width: 50%;
}

@media (max-width: 600px) {
	.SquadOverview {
		flex-direction: column;
		/* width: fit-content; */
	}

	.SquadStatWrapper {
		gap: 20px;
		width: 100%;
	}

	.SquadRoleIcon {
		width: 12px;
	}

	.SquadRoleText{
		font-size: 0.8em;
	}

	.SquadStat{
		font-size: 0.7em;
		color: #868C96;
	}
}