body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #010815;
	color: #FFF;
}

.PageHeading {
	font-weight: 400;
	margin: 35px;
	opacity: 0.75;
	font-size: 1.2em;
}

.SpinnerContainer {
	display: block;
	width: 100%;
}

@media (max-width: 600px) {
	.PageHeading {
		margin-bottom: 35px;
		text-align: center;
		font-size: 0.9em;
		opacity: 0.9;
	}
}

a {
	text-decoration: none;
}

p {
	line-height: 1.3em;
}

span {
	line-height: 1.3em;
}