.SavedSquad {
	width: 100px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.SavedSquadLetter {
	font-size: 2em;
	background-color: #142C3F;
	padding: 10px 5px 10px 5px;
	width: 60%;
	margin: auto;
	border-radius: 5px;
}

.SquadName{
	text-align: center;
}