.WikiTextInput {
	width: 100%;
	min-height: 360px;
	background-color: #283143;
	color: #686E7B;
	border-radius: 5px;
	font-size: 1em;
	border: none;
}

.WikiTextWrapper {
	text-align: left;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.EmptyInput {
	border: 1px solid red;
}