.NavBar {
	display: flex;
	flex-direction: row;
	gap: 30px;
	margin: auto auto 100px auto;
	align-items: center;
	padding: 10px;
	background-color: #000A1D;
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 999;
}

.gogLogo{
	width: 30px;
}

.NavWrapper {
	max-width: 1280px;
	width: 100%;
	display: flex;
	margin: auto;
	gap: 120px;
	justify-content: space-between;
}

.NavContent {
	display: flex;
	flex-direction: row;
	width: 40%;
	justify-content: space-between;
}

.NavGreeting {
	display: flex;
	justify-content: center;
	gap: 15px;
}

.NavUserName {
	font-weight: 600;
}

.NavBarItem {
	font-size: 0.85em;
	transition: all 0.7s;
}

.NavBarItem:hover {
	opacity: 0.5;
}

a{
	margin: 0;
}