.AddDungeonInputWrapper{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.DungeonsWrapper {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 100px;
	width: fit-content;
	margin: auto;
}

.MainInputWrapper{
	display: flex;
	flex-direction: column;
	gap: 35px;
	width: 40%;
}

.MainDropdownWrapper{
	display: flex;
	flex-direction: column;
	/* width: 35%; */
	gap: 35px;
}

.AddDungeonContent{
	display: flex;
	flex-direction: column;
	gap: 100px;
}