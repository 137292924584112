.DungeonLevelAttributesWrapper {
	height: 30%;
	transition: all 1s;
}

.DungeonLevelAttributes {
	display: flex;
	flex-direction: column;
	background-color: #030D17;
	opacity: 0.9;
	height: 100%;
	gap: 5px;
	text-align: left;
	padding: 10px 20px 0 20px;
}

.DungeonLevelCard {
	width: 240px;
	height: 370px;
	border-radius: 5px;
	/* display: flex; */
}

.DungeonLevelImage {
	height: 100%;
}

.CardOverlay {
	height: 70%;
	background-color: #283143;
	opacity: 0.2;
}

.DungeonLevelName {
	font-weight: 700;
	font-size: 1.2em;
	color: #BAC8D4;
	width: fit-content;
}

.DungeonLevelNameWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

/* .NavBarContentWrapper {} */

.DungeonRecommendedText {
	color: #6E7480;
}

.ElipsisWrapper {
	width: 25px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ElipsisWrapper:hover {
	border-radius: 50px;
	background-color: rgba(256, 256, 256, 0.1);
}

.DungeonLevelAttributesWrapper:hover {
	background-color: #1D2637;
}

.ElipsisIcon {
	margin: auto;
}

@media (max-width: 600px) {
	.DungeonLevelCard {
		width: 150px;
		height: 200px;
	}

	.DungeonLevelName {
		font-size: 0.75em;
	}

	.DungeonRecommendedText {
		font-size: 0.6em;
	}

	.DungeonLevelAttributes {
		padding: 5px;
	}
}