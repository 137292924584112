.Tooltip {
	display: flex;
	gap: 10px;
	flex-direction: column;
	background-color: #283143;
	color: #030D17;
	height: 120px;
	z-index: 9999;
	position: absolute;
	width: 80px;
	height: fit-content;
	padding: 5px;
	border-radius: 5px;
	color: #FFF;
}

.ToolTipOption {
	background-color: transparent;
	width: 75px;
	border: none;
	display: flex;
	justify-content: center;
	color: #F5F5F5;
	margin: auto;
	border-radius: 2px;
	padding: 2px 5px;
}

.ToolTipOption:hover {
	background-color: #454C59;
}