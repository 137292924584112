.SearchWrapper {
	display: flex;
	flex-direction: row;
	max-width: 450px;
	margin: auto;
	padding: 10px;
	gap: 40px;

}

.SearchInput {
	background-color: #283143;
	border: none;
	border-radius: 5px;
	width: 20rem;
	color: #686E7B;
	font-size: 1em;
	padding: 10px;
}

@media (max-width: 600px) {
	.Search {
		display: flex;
		flex-direction: column;
	}

	.SearchWrapper{
		gap: 20px;
	}

	.SearchInput{
		min-width: 150px;
		width: 40%;
		font-size: 0.7em;
		padding: 5px;
		height: 20px;
	}
}