.GuardianCardWrapper {
	display: flex;
	flex-direction: column;
	margin: auto;
	border: 5px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.AttributeIcon {
	height: 20px;
}

.GuardianCardInfo {
	display: flex;
	flex-direction: row;
	gap: 60px;
	align-items: center;
	text-align: left;
	background-color: #030D17;
	opacity: 0.9;
	border: 5px;
}

.GuardianCardInfoWrapper{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.GuardianCardInfo :hover {
	background-color: #1D2637;
}
.GuardianImage {
	width: 100%;
	height: 120px;
	border: none;
}

.GuardianName {
	font-size: 1.6em;
	color: #BAC8D4;
}

.GuardianTagline {
	font-size: 0.8em;
	font-weight: 300;
	color: #B0BEDA;
	opacity: 0.65;
}

.GuardianPrice {
	font-size: 1.2em;
}

.GuardianPriceDollars {
	font-size: 0.8em;
	color: #B0BEDA;
}

.Guardian {
	display: flex;
	flex-direction: column;
	margin: auto;
	padding: 15px;
	gap: 20px;
	transition: all 1s;
}

.GuardianNameWrapper {
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 70%;
}

.GuardianPriceWrapper {
	display: flex;
	flex-direction: column;
	margin: auto;
	gap: 10px;
}

.GuardianAttributeWrapper {
	display: flex;
	flex-direction: row;
	gap: 60px;
}

.GuardianAttribute {
	display: flex;
	flex-direction: row;
	gap: 5px;
}

.GuardianAttributeTextWrapper {
	display: flex;
	flex-direction: column;
}

.GuardianAttributeHeading {
	color: #B0BEDA;
	opacity: 0.65;
	font-size: 0.7em;
}

.GuardianAttributeText {
	color: #858A95;
}

.ElipsisWrapper {
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ElipsisWrapper:hover {
	border-radius: 50px;
	background-color: rgba(256, 256, 256, 0.1);
}

.ElipsisIcon {
	margin: auto;
}

@media (max-width: 1280px) {
	.GuardianCardWrapper {
		width: 200px;
	}

	.Guardian {
		padding: 10px;
	}
}

@media (max-width: 780px) {
	.GuardianCardInfo {
		gap: 20px;
	}

	.GuardianAttributeWrapper {
		gap: 20px;
	}
}

@media (max-width: 600px) {
	.GuardianCardWrapper {
		width: 150px;
		/* height: 200px; */
		background-position: center;
		margin-bottom: 20px;
	}

	.GuardianCardInfo {
		padding: 0px;
	}

	.GuardianImage {
		height: 120px;
	}

	.GuardianName {
		font-size: 1em;
	}

	.GuardianTagline {
		font-size: 0.6em;
	}

	.GuardianPrice {
		font-size: 1em;
	}

	.GuardianAttributeHeading {
		font-size: 0.6em;
	}

	.GuardianAttributeText {
		font-size: 0.7em;
	}

	.AttributeIcon {
		height: 18px;
	}

	.GuardianPriceDollars {
		font-size: 0.6em;
	}
}