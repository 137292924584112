.MainDropdownWrapper{
	display: flex;
	flex-direction: column;
	width: 250px;
	text-align: left;
	gap: 10px;
}

.MainDropdown{
	background-color: #283143;
	border: none;
	padding: 12px 5px 12px 0px;
	border-radius: 5px;
	color: #686E7B;
}