.TeamCompositionOverlayBackground {
	background-color: rgba(0, 10, 28, 0.9);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	padding: 20px 0 0 0;
	overflow-y: auto;
}

.SquadNumberWrapper {
	height: 60px;
	display: flex;
	flex-direction: row;
	background-color: #000A1D;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	align-items: center;
	margin: auto;
	justify-content: center;
	z-index: 99;
}

.SquadNumber {
	display: flex;
	flex-direction: row;
	/* gap: 500px; */
	justify-content: space-between;
	width: 100%;
	max-width: 1280px;
	padding: 0 20px;
	align-items: center;
}

.SquadNumberText {
	font-size: 1em;
}

.SquadCardContainer {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, auto));
	gap: 60px;
	width: 100%;
	align-items: center;
	justify-content: space-around;
}

.Overlay {
	background-color: #FFF;
	width: 100vw;
	height: 100%;
}

@media (max-width: 600px) {
	.SquadCardContainer {
		grid-template-columns: 1fr 1fr;
		gap: 40px;
		justify-content: space-around;
	}

	.SquadNumberText {
		font-size: 0.75em;
	}
}