.DungeonInfoContainer {
	display: flex;
	flex-direction: row;
	gap: 40px;
	justify-content: center;
	margin-bottom: 40px;
}

.DungeonInfoWrapper {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.DungeonInfo {
	display: flex;
	flex-direction: column;
	text-align: left;
	gap: 5px;
}

.DungeonsWrapper {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 100px;
	width: fit-content;
	margin: auto;
}

.DungeonAttribute{
	font-size: 1.2em;
	font-weight: 600;
	color: #848A93;
}

.DungeonInfoHeading{
	font-size: 0.8em;
	color: #767C85;
}

.PageHeading{
	font-weight: 600;
}

@media (max-width: 1000px) {
	.DungeonsWrapper{
		grid-template-columns: 1fr 1fr 1fr;
		gap: 60px;
	}
}

@media (max-width: 600px) {
	.DungeonInfoWrapper{
		gap: 10px;
	}

	.DungeonsWrapper{
		grid-template-columns: 1fr 1fr;
		gap: 25px;
	}

	.DungeonInfo{
		gap: 2px;
	}

	.DungeonInfoWrapper img{
		width: 15px;
	}

	.DungeonInfoHeading{
		font-size: 0.6em;
	}

	.DungeonAttribute{
		font-size: 0.8em;
	}
}